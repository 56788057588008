import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: {
                color: "primary",
                icon: "mdi-office-building",
                inline: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after-heading",
                    fn: function () {
                      return [
                        _c("h1", { staticClass: "h5" }, [
                          _c("b", [_vm._v(_vm._s(_vm.$tc("thesis", 2)))]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3734675277
              ),
            },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    "data-test": "Thesis:ThesesTable:BtnCreateNewThesis",
                    color: "primary",
                    rounded: "",
                    absolute: "",
                    fab: "",
                    top: "",
                    right: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.add_thesis()
                    },
                  },
                },
                [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
                1
              ),
              _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("name")))]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(_vm.$t("details")) + " "),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(_vm.$t("edit")) + " "),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(_vm.$t("remove")) + " "),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.theses, function (thesis, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        attrs: { "data-test": "Thesis:ThesesTable:TableItem" },
                      },
                      [
                        _c(
                          "td",
                          {
                            attrs: {
                              "data-test":
                                "Theses:ThesesTable:TdName:" + thesis.name,
                            },
                          },
                          [_vm._v(" " + _vm._s(thesis.name) + " ")]
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "px-2 ml-1 primary",
                                attrs: {
                                  "data-test":
                                    "Thesis:ThesesTable:BtnSeeThesis" + index,
                                  "min-width": "0",
                                  small: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.redirect_details(thesis.id)
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-eye"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "px-2 ml-1 primary",
                                attrs: {
                                  "data-test":
                                    "Thesis:ThesesTable:BtnEditThesis" + index,
                                  "min-width": "0",
                                  small: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit_thesis(thesis.id)
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-pencil"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "px-2 ml-1",
                                attrs: {
                                  "data-test":
                                    "Thesis:ThesesTable:BtnDeleteThesis" +
                                    index,
                                  color: "red",
                                  "min-width": "0",
                                  small: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.open_delete_dialog(thesis)
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-trash-can"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm.delete_dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.delete_dialog,
                    callback: function ($$v) {
                      _vm.delete_dialog = $$v
                    },
                    expression: "delete_dialog",
                  },
                },
                [
                  _c("DeleteConfirmationModal", {
                    attrs: { obj: _vm.delete_obj, name: _vm.delete_obj.Name },
                    on: {
                      close: function ($event) {
                        _vm.delete_dialog = false
                      },
                      delete: _vm.delete_thesis,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VRow,
        { staticClass: "pa-4", attrs: { justify: "center", align: "center" } },
        [
          _c(VProgressCircular, {
            attrs: { indeterminate: "", size: "40", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }